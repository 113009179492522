import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    // => «connect» method is similar to initialize method in a ruby-class
    console.log('Hello, Stimulus themeswitch controller!')
    let targetTheme = localStorage.getItem('theme')
    if (targetTheme === null) {
      localStorage.setItem('theme', 'normal')
      targetTheme = 'normal'
    }
    document.getElementById('html_tag').setAttribute('data-theme', targetTheme)
    console.log('targetTheme')
    this.top_menu_style(targetTheme)
  }

  accessabilityMode () {
    const container = document.getElementById('html_tag')
    // Check what is the current theme and get the opposite one
    const targetTheme =
      container.getAttribute('data-theme') === 'normal' ? 'accessability' : 'normal'

    // Set the attribute 'data-theme' to the targetTheme
    container.setAttribute('data-theme', targetTheme)

    this.top_menu_style(targetTheme)
    // Save the targetTheme to the localstorage
    localStorage.setItem('theme', targetTheme)
    console.log(targetTheme)
  }

  top_menu_style (targetTheme) {
    const menu = document.getElementById('main-menu')
    console.log('rrr')
    console.log(targetTheme)
    if (targetTheme === 'accessability') {
      // menu.classList.remove('menu-horizontal')
      console.log('horizontal removed')
    } else {
      // menu.classList.add('menu-horizontal')
      console.log('horizontal added')
    }
  }
}
